import './main.scss';
import AppBar from './app-bar';
import Content from './content';

function MainLayout({ children }) {
  return (
    <div className='main-layout bg-image'>
      <AppBar />
      <Content>
        {children}
      </Content>
    </div>
  );
}

export default MainLayout;
