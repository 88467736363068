import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Box, Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadNotice, getNotices, deleteNotice } from '../../utils/apiRoutes';
import moment from 'moment';

const NoticeListPage = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showGrid, setShowGrid] = useState(true);
    const [columns] = useState([
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'title', headerName: 'Título', width: 600 },
        { field: 'created_at', headerName: 'Criado Em', width: 225 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => navigate(`/editais/${params.id}`)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDelete(params.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ]);

    const fetchNotices = async () => {
        try {
            const response = await getNotices();

            setRows(response.data.notices.map((notice) => ({
                id: notice.id,
                title: notice.title,
                created_at: moment(notice.created_at).format('DD/MM/YYYY [às] HH:mm:ss')
            })));
        } catch (error) {
            // console.log("error", error)
            toast.error('Falha ao buscar editais');
        }
    }

    const handleDelete = async (noticeId) => {
        try {
            const response = await deleteNotice(noticeId);

            setRows((prevRows) => prevRows.filter((notice) => notice.id !== noticeId));

            toast.success("Edital excluído!");
        } catch (error) {
            // console.log("error", error)
            toast.error('Falha ao excluir edital');
        }
    }

    useEffect(() => {
        fetchNotices();
    }, [])

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleBack = () => {
        setShowGrid(true);
    }

    const handleUpload = async () => {
        if (!file) {
            toast.error('Selecione um arquivo para fazer upload!');
            return;
        }

        setLoading(true);

        try {
            let payload = new FormData();
            payload.append('file', file);

            const response = await uploadNotice(payload);
            const noticeId = response.data.id;

            toast.success("Sucesso!");
            localStorage.setItem('processNotice', true);
            setTimeout(() => {
                navigate(`/editais/${noticeId}`)
            }, 2000);
        } catch (error) {
            toast.error('Falha ao realizar upload');
            setLoading(false);
        }
    };

    const handleNewUpload = () => {
        setShowGrid(false);
        setFile(null);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
            <ToastContainer />
            <Paper sx={{ padding: 4, width: '100%', maxWidth: 1200, boxShadow: 3, margin: { xs: 1, sm: 2 } }}>
                {!showGrid ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
                        <IconButton
                            sx={{ position: 'absolute', top: 8, left: 8 }}
                            onClick={handleBack}
                        >
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography variant="h6" gutterBottom>
                            Upload de edital
                        </Typography>
                        <Button
                            variant="contained"
                            component="label"
                            color="error"
                            startIcon={<CloudUploadIcon />}
                            sx={{ mb: 2 }}
                        >
                            Selecione o arquivo
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>

                        {file && (
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                Arquivo selecionado: {file.name}
                            </Typography>
                        )}

                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleUpload}
                            disabled={loading || !file}
                            sx={{ mt: 2, width: '100%', maxWidth: 200 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Upload'}
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Editais
                        </Typography>
                        <Box sx={{ height: 400, width: '100%', mt: 2, marginX: { xs: 1, sm: 2 } }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                pagination
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'asc' }],
                                    },
                                }}
                            />
                        </Box>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleNewUpload}
                            sx={{ mt: 2 }}
                        >
                            Fazer novo upload
                        </Button>
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default NoticeListPage;
