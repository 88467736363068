import * as React from "react";

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    aria-labelledby="logo-alt"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 485.52 223.55"
    {...props}
  >
    <title id="logo-alt">Thermo Fisher Scientific Logo</title>
    <defs>
      <style>{`
        .tf-logo-cls-1 {
          fill: #E71316;
        }
        .tf-logo-cls-2 {
          fill: #fff;
        }
    `}
      </style>
    </defs>
    <rect className="tf-logo-cls-1" width="485.52" height="223.55"></rect>
    <path className="tf-logo-cls-2" d="M108.27,83.11V76h-32v7.13h11v31h9.92v-31Zm123.5,31c-.26-2.53-.42-4.49-.42-5.81V95.46c0-5.33-3.91-8.65-9.08-8.65a10.21,10.21,0,0,0-8.82,4.69c-1.11-3-4.38-4.54-7.44-4.54-3.7,0-6.81,1.22-8.55,4.54l-.22-4h-9.07V114.1h9.39v-16c0-.84-.37-5.38,4-5.38,2.91,0,3.59,2.59,3.59,5.12V114.1h9.34v-16c0-.84-.37-5.38,4-5.38,2.91,0,3.6,2.59,3.6,5.12V114.1ZM186,95.35V87.28c-4.07,0-7.18,1.27-8.39,5.33l-.22-5.07H168c.21,2.17.21,5.07.21,6.82V114.1h9.4V102.48c0-4.38,1.26-7.07,6.44-7.17.63,0,1.26,0,1.95,0m62-8.54c-8.66,0-14.31,4.8-14.31,13.93,0,9.4,5.71,14.1,14.31,14.1s14.31-4.7,14.31-14.1c0-9.13-5.65-13.93-14.31-13.93m0,5.59c4,0,4.7,4.06,4.7,8.23,0,4.86-.85,8.66-4.7,8.66s-4.7-3.8-4.7-8.66c0-4.22.74-8.28,4.7-8.28ZM137.21,114.1a57.55,57.55,0,0,1-.37-5.81V95.46c0-5.33-4-8.65-9.13-8.65a11.12,11.12,0,0,0-4.44.84,9.58,9.58,0,0,0-3.38,2.69V76H110.5V114.1h9.39v-16c0-.84-.36-5.38,4-5.38,3,0,3.59,2.59,3.59,5.12V114.1Zm28.72-11.46c0-9.55-2.8-15.83-13.41-15.83-8.39,0-13.41,5.17-13.41,13.25,0,9.92,3.54,14.78,13.88,14.78a14.49,14.49,0,0,0,8.24-2.22,9.58,9.58,0,0,0,4.49-7.29h-8.87a4.15,4.15,0,0,1-4.49,4.07c-3.59,0-4.32-3.43-4.27-6.76Zm-9-5.12h-8.71c.21-2.8.84-5.65,4.32-5.8a3.49,3.49,0,0,1,3.33,1.84,7.36,7.36,0,0,1,1.06,4Z"></path>
    <path className="tf-logo-cls-2" d="M409.29,94.88V86.81c-4.06-.06-7.18,1.26-8.39,5.33l-.21-4.6H391.3c.2,2.17.2,5.07.2,6.81v19.74h9.4V102.48c0-4.38,1.27-7.54,6.44-7.65.63,0,1.26,0,2,0m-48.87,19.21a54.56,54.56,0,0,1-.37-5.8V95.47c0-5.34-4-8.66-9.13-8.66a10.52,10.52,0,0,0-4.33.84,9.73,9.73,0,0,0-3.49,2.69V76h-9.39v38.11h9.39v-16c0-.84-.37-5.38,4-5.38,2.95,0,3.59,2.58,3.59,5.12v16.25Zm-29-7.91c0-4.12-2.16-6.12-5.17-7.23-5.65-2-9.88-2.11-9.88-4.23a2.24,2.24,0,0,1,2.44-2.48,3.34,3.34,0,0,1,2.52.85,3.7,3.7,0,0,1,.69,2.53h8.92c-.21-7-5.7-8.81-11.72-8.81-5.12,0-11.76,1.79-11.76,8.18,0,9.29,15,6.86,15,11.82,0,1.75-1.43,2.53-3.28,2.53a2.92,2.92,0,0,1-2.42-1.26,3.36,3.36,0,0,1-.58-2.59h-9c-.16,7.92,5.7,9.35,12.19,9.35,6.08,0,12-2.22,12-8.66m57.78-3.54c.06-9.55-2.79-15.83-13.41-15.83-8.39,0-13.41,5.17-13.41,13.25,0,9.92,3.54,14.78,13.89,14.78a14.56,14.56,0,0,0,8.23-2.22,9.52,9.52,0,0,0,4.49-7.29H380.1a4.14,4.14,0,0,1-4.48,4.07c-3.59,0-4.33-3.43-4.28-6.76Zm-9-5.12H371.5c.21-2.8.84-5.65,4.33-5.8a3.51,3.51,0,0,1,3.33,1.85,7.38,7.38,0,0,1,1,4Zm-75.39-10h-9.39V114.1h9.39ZM292.5,83l2.22-7H269.47v38.11h9.92v-15h8l2.21-7H279.39V83Zm12.32,0,2.23-7h-9.29l-2.22,7Z"></path>
    <path className="tf-logo-cls-2" d="M162.45,125.26h3.44v21.61h-3.44Zm232,22c-7.21,0-12.59-4-12.59-11s6-11.42,12.81-11.42a13.4,13.4,0,0,1,6.78,1.9l-1.77,2.09a10.44,10.44,0,0,0-5-1.41,9,9,0,0,0-6.85,2.64,8.89,8.89,0,0,0-2.18,6.33,7.9,7.9,0,0,0,2.56,6.3c1.65,1.63,4.18,2.08,6.66,2.08a12.1,12.1,0,0,0,5.46-1.55l1.68,2a14.64,14.64,0,0,1-7.59,2m-36.24-22h3.44v21.61h-3.44Zm-77.92,2.58h-8.05v19h-3.36v-19h-8v-2.58h19.77ZM100.78,146.1a13.66,13.66,0,0,1-6.09,1.22A20.13,20.13,0,0,1,86,145.37l1.43-2.45a16.3,16.3,0,0,0,7.54,1.82,7.65,7.65,0,0,0,3.93-.88,3.14,3.14,0,0,0,1.9-2.79c0-1.81-1.39-2.93-4.73-3.71l-3.51-.82c-3.5-.82-5.7-3-5.7-5.56,0-3.68,3.62-6.29,8.78-6.29a16.39,16.39,0,0,1,8.29,2.06L102.33,129a13.46,13.46,0,0,0-6.55-1.73c-3.07,0-5.2,1.19-5.2,3.22,0,1.56,1.3,2.49,4.31,3.22l3.25.78a11.33,11.33,0,0,1,4.78,2.18,5.38,5.38,0,0,1,1.68,3.86,6.4,6.4,0,0,1-3.82,5.57m138.41.77-8.58-11.52c-1.61-2.16-4.33-6.38-4.33-6.38s.3,3.65.32,6.85l.09,11.05h-3.43V125.26h4.09L236,137c1.69,2.29,4,5.92,4,5.92s-.52-4-.54-6.84l-.08-10.85h3.4v21.61Zm59.2-21.61h3.45v21.61h-3.45Zm41,2.54H327.56v6.4h10v2.54h-10v10.13h-3.45V125.26h15.76ZM188.17,146.87V125.26H204.1l-.46,2.58h-12v6.44h10.24v2.58h-10.2v7.3h12.86v2.71Zm-52.36.37c-7.2,0-12.58-4-12.58-11s6-11.42,12.8-11.42a13.4,13.4,0,0,1,6.78,1.9l-1.76,2.09a10.44,10.44,0,0,0-5-1.41,9,9,0,0,0-6.86,2.64,8.89,8.89,0,0,0-2.18,6.33,7.9,7.9,0,0,0,2.57,6.3c1.65,1.63,4.17,2.08,6.65,2.08a12.1,12.1,0,0,0,5.46-1.55l1.68,2a14.59,14.59,0,0,1-7.59,2"></path>
  </svg>
);

export default SvgComponent;
