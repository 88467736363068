import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundColor: '#ffffff', // Fundo totalmente branco
            }}
        >
            <Typography variant="h1" sx={{ fontSize: '10rem', fontWeight: 'bold', color: '#1976d2' }}>
                404
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
                Página não encontrada
            </Typography>
            <Typography variant="body1" sx={{ mb: 5 }}>
                Parece que a página que você está procurando não existe.
            </Typography>
            {/* <Box>
                <Button
                    variant="contained"
                    onClick={handleGoHome}
                    sx={{
                        padding: '10px 20px',
                    }}
                >
                    Voltar para Home
                </Button>
            </Box> */}
        </Container>
    );
};

export default NotFoundPage;
