import React, { useState } from 'react';
import { Button, CircularProgress, Box, Paper, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataGrid } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadEventUsers } from '../../utils/apiRoutes';

const UploadPage = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [showGrid, setShowGrid] = useState(false);
    const [columns] = useState([
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'name', headerName: 'Nome', width: 225 },
        { field: 'event_name', headerName: 'Evento', width: 225 },
        { field: 'city', headerName: 'Cidade', width: 150 },
        { field: 'occupation', headerName: 'Ocupação', width: 200 },
    ]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            toast.error('Selecione um arquivo para fazer upload!');
            return;
        }

        setLoading(true);

        try {
            let payload = new FormData();
            payload.append('file', file);

            const response = await uploadEventUsers(payload);

            console.log("response", response)

            toast.success(response.data.message);
            setRows(response.data.event_users.map((user) => ({
                id: user.id,
                email: user.email,
                name: user.name,
                event_name: user.event_data.event_name,
                city: user.event_data.city,
                occupation: user.event_data.occupation
            })));
            setShowGrid(true);

        } catch (error) {
            toast.error('Falha ao realizar upload');
        } finally {
            setLoading(false);
        }
    };

    const handleNewUpload = () => {
        setShowGrid(false);
        setFile(null);
        setRows([]);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}>
            <ToastContainer />
            <Paper sx={{ padding: 4, width: '100%', maxWidth: 1200, boxShadow: 3, margin: { xs: 1, sm: 2 } }}>
                {!showGrid ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant="h6" gutterBottom>
                            Upload de usuários do evento
                        </Typography>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            sx={{ mb: 2 }}
                        >
                            Selecione o arquivo
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>

                        {file && (
                            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                Arquivo selecionado: {file.name}
                            </Typography>
                        )}

                        <Button
                            variant="contained"
                            onClick={handleUpload}
                            disabled={loading || !file}
                            sx={{ mt: 2, width: '100%', maxWidth: 200 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Upload'}
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Usuários adicionados
                        </Typography>
                        <Box sx={{ height: 400, width: '100%', mt: 2, marginX: { xs: 1, sm: 2 } }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                pagination
                            />
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={handleNewUpload}
                            sx={{ mt: 2 }}
                        >
                            Fazer novo upload
                        </Button>
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default UploadPage;
