import React, { useState, useEffect, useRef } from 'react';
import { socket } from '../../utils/socket';
import Grid from '@mui/material/Grid';
import Textarea from '@mui/joy/Textarea';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import DeleteIcon from '@mui/icons-material/Delete';

import Spectrogram from '../spectogram'; // Certifique-se de importar o componente Spectrogram

import './question-bar.scss';

export default ({ context, setContext, loading }) => {
    const [question, setQuestion] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [audioContext, setAudioContext] = useState(new AudioContext());
    const [sampleRate, setSampleRate] = useState();
    const [localStream, setLocalStream] = useState();
    const [rivaRunning, setRivaRunning] = useState(false);
    const [analyserNode, setAnalyserNode] = useState(null); // Novo estado para o AnalyserNode
    const questionInputRef = useRef(null);

    const sendQuestion = () => {
        if (loading || !question) return;

        const newContext = [
            ...context,
            { message_type: isRecording ? 'audio' : 'text', user_type: 'client', message: question, id: context.length },
        ];

        setIsRecording(false);
        stopRivaService();
        setContext(newContext);
        setQuestion('');
    };

    const handleKeyPress = (event) => {
        if (loading) return;

        if (event.key === 'Enter' && event.target.value) {
            event.preventDefault();
            sendQuestion();
        }
    };

    const handleRecordClick = () => {
        questionInputRef.current.focus();
        setIsRecording(true);
        startRivaService();
    };

    const handleDiscardButton = () => {
        setIsRecording(false);
        stopRivaService();
        setQuestion('');
    };

    function SendButton() {
        return (
            <IconButton className="__button --red-background" onClick={sendQuestion}>
                <SendIcon className="send-icon" disabled={loading} />
            </IconButton>
        );
    }

    function RecordButton() {
        return (
            <IconButton className="__button --red-background" onClick={handleRecordClick}>
                <KeyboardVoiceIcon className="record-icon" />
            </IconButton>
        );
    }

    function DiscardButton() {
        return (
            <IconButton className="__button --blue-background" onClick={handleDiscardButton}>
                <DeleteIcon className="discard-icon" />
            </IconButton>
        );
    }

    function requestLocalAudio() {
        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    noiseSuppression: true,
                    echoCancellation: true,
                },
                video: false,
            })
            .then((stream) => {
                setLocalStream(stream);
                setSampleRate(audioContext.sampleRate);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const stopRivaService = () => {
        socket.disconnect();
        audioContext.close();
        setAudioContext(new AudioContext());

        setRivaRunning(false);
        setAnalyserNode(null);
    };

    const startRivaService = () => {
        if (!socket.connected) {
            socket.connect();
        }

        audioContext.resume();

        if (rivaRunning) return;

        let audioInput = audioContext.createMediaStreamSource(localStream);
        let bufferSize = 4096;
        let recorder = audioContext.createScriptProcessor(bufferSize, 1, 1);
        let worker = new Worker('/resampler.js');

        worker.postMessage({
            command: 'init',
            config: {
                sampleRate: sampleRate,
                outputSampleRate: 16000,
            },
        });

        // Cria o AnalyserNode e conecta ao fluxo de áudio
        let analyser = audioContext.createAnalyser();
        audioInput.connect(analyser);
        setAnalyserNode(analyser);

        // Conecta o AnalyserNode ao recorder
        analyser.connect(recorder);

        // Processa o áudio
        recorder.onaudioprocess = function (audioProcessingEvent) {
            let inputBuffer = audioProcessingEvent.inputBuffer;
            worker.postMessage({
                command: 'convert',
                buffer: inputBuffer.getChannelData(0),
            });
            worker.onmessage = function (msg) {
                if (msg.data.command === 'newBuffer') {
                    socket.emit('audio_in', msg.data.resampled.buffer);
                }
            };
        };

        recorder.connect(audioContext.destination);
        setRivaRunning(true);
    };

    useEffect(() => {
        requestLocalAudio();

        socket.on('transcript', function (result) {
            const transcript = result.transcript.trim();
            if (
                transcript === undefined ||
                transcript.toLowerCase() === 'sonant'
            )
                return;

            if (result.is_final) {
                setQuestion((prevTranscription) => {
                    const updatedTranscription =
                        prevTranscription + ' ' + transcript;

                    return updatedTranscription;
                });
            }
        });
    }, []);

    return (
        <Grid
            container
            item
            className="question-bar"
            gap={0}
            justifyContent="space-between"
            alignItems="center"
        >
            {isRecording ? <DiscardButton /> : <RecordButton />}
            {isRecording ? (
                <Spectrogram analyserNode={analyserNode} />
            ) : (
                <Textarea
                    variant="plain"
                    className="__input"
                    placeholder="Digite sua resposta aqui"
                    value={question}
                    onChange={({ target }) => setQuestion(target.value)}
                    onKeyPress={handleKeyPress}
                    slotProps={{ textarea: { ref: questionInputRef } }}
                />
            )}
            <SendButton />
        </Grid>
    );
};
