import React from "react";
import Grid from '@mui/material/Grid'
import './content.scss'

export default function Content({ children }) {
    return (
        <Grid container item className="main-content" xs={12} >
            <Grid container item justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                {children}
            </Grid>
        </Grid>
    )
}