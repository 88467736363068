import React, { useRef, useEffect } from 'react';

export default function Spectrogram({ analyserNode }) {
    const canvasRef = useRef(null);
    const requestRef = useRef();

    useEffect(() => {
        if (!analyserNode) return;

        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');

        const draw = () => {
            requestRef.current = requestAnimationFrame(draw);
            const WIDTH = canvas.clientWidth; // Ajusta a largura dinamicamente conforme o container
            const HEIGHT = canvas.height;

            canvas.width = WIDTH; // Garante que o canvas se ajuste ao novo tamanho

            analyserNode.fftSize = 2048;
            const bufferLength = analyserNode.fftSize;
            const dataArray = new Uint8Array(bufferLength);

            analyserNode.getByteTimeDomainData(dataArray);

            // Limpa o canvas e define o fundo branco
            canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
            canvasCtx.fillStyle = 'rgb(255, 255, 255)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            // Cor da linha do espectrograma
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

            // Desenha a linha do espectrograma
            canvasCtx.beginPath();

            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * HEIGHT) / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(WIDTH, HEIGHT / 2);
            canvasCtx.stroke();
        };

        draw();

        return () => {
            cancelAnimationFrame(requestRef.current);
        };
    }, [analyserNode]);

    return (
        <canvas
            ref={canvasRef}
            height="35"
            style={{ borderRadius: '8px', marginTop: '10px', width: '100%' }}
        />
    );
}
